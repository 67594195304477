import * as React from "react"
import styled, {createGlobalStyle} from "styled-components";
import normalize from "styled-normalize";
import {useEffect, useRef, useState} from "react";
import PollMenu from "../../molecules/PollMenu/PollMenu";

const GlobalStyle = createGlobalStyle`
  ${normalize};

  *, *:before, *:after {
    box-sizing: border-box;
  }

  body {
    padding:0;
    margin:0;
  }
  
  html, body {
      height: 100%;
  }
`

const MainStyled = styled.div`
  position: absolute;
  top:50%;
  left:50%;
  width:100%;
  transform: translate(-50%, -50%);
  max-width: 428px;
  // min-height: 600px;
  height: 100%;
  max-height: 720px;
  margin:0 auto;
  overflow: hidden;
  border:1px solid #ccc;
`;

const MainContent = styled.div`
  overflow: auto;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
`;


const Main = ({children, footerContent, poll=false}) => {
  const ref = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(ref.current.scrollTop);
    }
    ref.current.addEventListener("scroll", updatePosition);
    updatePosition();
    // return () => ref.current.removeEventListener("scroll", updatePosition);
  }, [ref.current]);

  return (
    <MainStyled>
      <GlobalStyle/>
      {poll ? (
        <PollMenu show={scrollPosition > 200} />
      ) : null}
      <MainContent ref={ref}>
        {children}
      </MainContent>
      {footerContent}
    </MainStyled>
  );
}

export default Main;
