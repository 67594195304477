import FooterNav, {FooterButton, FooterButtonText} from "../FooterNav/FooterNav";
import * as React from "react";
import HomeIcon from "../../atoms/Icons/HomeIcon";
import NewsIcon from "../../atoms/Icons/NewsIcon";
import SpieleIcon from "../../atoms/Icons/SpieleIcon";
import ChatIcon from "../../atoms/Icons/ChatIcon";
import {Link} from "gatsby";

const items = [
  {
    name: 'home',
    to: '/app',
    icon: HomeIcon,
    title: 'Home',
  },
  {
    name: 'news',
    to: '/app/news',
    icon: NewsIcon,
    title: 'News'
  },
  {
    name: 'spiele',
    to: '/app/spiele',
    icon: SpieleIcon,
    title: 'Spiele',
  },
  {
    name: 'chat',
    to: '/app/chat',
    icon: ChatIcon,
    title: 'Chat',
  }
];


const MainNav = ({active=null}) => (
  <FooterNav>
    {items.map((item, index) => (
      <FooterButton to={item.to} key={`nav-${index}`}>
        <item.icon color={(active === item.name) ? '#c80039' : '#999'}/>
        <FooterButtonText color={(active === item.name) ? '#c80039' : '#999'}>{item.title}</FooterButtonText>
      </FooterButton>
    ))}
  </FooterNav>
);

export default MainNav;