import * as React from "react"
import styled from "styled-components";

const Svg = styled.svg`
`;

const SpieleIcon = ({color = '#999'}) => (
  <Svg width="30" height="30" viewBox="0 0 7.938 7.938" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path
        d="M3.982 2.769a.198.198 0 0 0 0-.396 1.582 1.582 0 1 0 1.582 1.583.198.198 0 1 0-.396 0 1.187 1.187 0 1 1-1.186-1.187z"
        />
      <path
        d="M7.755 2.781a.198.198 0 0 0-.378.118A3.573 3.573 0 1 1 5.038.56a.198.198 0 1 0 .118-.377 3.969 3.969 0 1 0 2.6 2.599z"
        />
      <path
        d="M4.358 1.412a.214.214 0 0 0-.198-.215 2.02 2.02 0 0 0-.178-.01A2.769 2.769 0 1 0 6.75 3.956c0-.072-.004-.143-.013-.214a.2.2 0 1 0-.39.069c.004.048.008.096.008.145a2.373 2.373 0 1 1-2.373-2.374c.049 0 .096.005.144.009l.037.003a.187.187 0 0 0 .195-.182z"
        />
      <path
        d="M5.168 1.78v.71L3.842 3.815a.198.198 0 1 0 .28.28l1.326-1.327h.71c.052 0 .102-.02.139-.058l1.187-1.187a.198.198 0 0 0-.14-.337H6.75V.593a.198.198 0 0 0-.337-.14L5.226 1.64a.198.198 0 0 0-.058.14zm.396.082.79-.791v.314a.198.198 0 0 0 .199.197h.313l-.79.791h-.512z"
        />
    </g>
  </Svg>
)

export default SpieleIcon;