import * as React from "react"
import styled from "styled-components";

const PollMenuStyled = styled.div`
  position: absolute;
  top:0;
  left:0;
  z-index:100;
  width:100%;
  background-color: #fff;
  padding: 12px 20px;  
  box-shadow: 0 7px 13px 0 rgba(0,0,0,0.1);
  border-radius: 0 0 14px 14px;
  transition: transform 0.3s ease-in-out;
  
  transform: ${props => props.show ? 'translateY(0);' : 'translateY(-100%)'};
`;

const PollMenuContent = styled.div`
  display: flex;
  align-items: center;
`;

const Paragraph = styled.p`
  font-family: 'PT Sans', sans-serif;
`;

const ButtonStyled = styled.a`
  background-color: #02A6D8;
  color: #fff;
  border-radius: 14px;
  font-family: 'PT Sans', sans-serif;
  padding: 14px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  display: block;
  flex:0 1 auto;
  width: 140px;
`;


const PollMenu = ({show=false}) => {
  return (
    <PollMenuStyled show={show}>
      <PollMenuContent>
        <Paragraph>
          Hilf uns, den Prototypen zu verbessern!
        </Paragraph>
        <ButtonStyled href="https://umfragen.verdi.de/index.php?r=survey/index&sid=322184&newtest=Y&lang=de-informal"
                      target="_blank">
          Zur Umfrage
        </ButtonStyled>
      </PollMenuContent>
    </PollMenuStyled>
  );
}

export default PollMenu;