import * as React from "react"
import styled from "styled-components";

const Svg = styled.svg`
`;

const HomeIcon = ({color = '#999'}) => (
  <Svg width="30" height="26.647" viewBox="0 0 7.938 7.05" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path
        d="M6.768 3.288v3.287a.482.482 0 0 1-.475.475H5.025a.482.482 0 0 1-.475-.475V5.17c0-.04-.04-.08-.08-.08h-.91c-.04 0-.08.04-.08.08v1.406a.482.482 0 0 1-.475.475H1.698a.482.482 0 0 1-.475-.475V3.288c0-.12.079-.198.198-.198.119 0 .198.079.198.198v3.287c0 .04.04.08.08.08h1.306c.04 0 .08-.04.08-.08V5.17c0-.257.217-.475.475-.475h.91c.258 0 .476.218.476.475v1.406c0 .04.04.08.08.08h1.267c.04 0 .079-.04.079-.08V3.288c0-.12.08-.198.198-.198.119 0 .198.099.198.198zm1.09-.357L4.074.06a.328.328 0 0 0-.377 0L.074 2.951a.213.213 0 0 0-.04.277c.04.04.1.08.159.08.04 0 .08-.02.119-.04L3.877.436 7.62 3.268c.079.06.218.06.277-.04.06-.099.06-.218-.04-.297z"
        />
    </g>
  </Svg>
)

export default HomeIcon;