import * as React from "react"
import styled from "styled-components";

const Svg = styled.svg`
`;

const ChatIcon = ({color = '#999'}) => (
  <Svg width="29.765" height="30" viewBox="0 0 7.875 7.938" xmlns="http://www.w3.org/2000/svg">
    <g className="verdi---team---home__group__chat__noun-chat-1683900" fill={color}>
      <path
        d="M.762 7.937a.347.347 0 0 1-.254-.092.383.383 0 0 1-.139-.37L.6 6.022A4.107 4.107 0 0 1 0 3.897C0 2.074 1.316.481 3.094.088a3.957 3.957 0 0 1 3.37.831 3.954 3.954 0 0 1 1.408 3.163C7.803 5.837 6.51 7.383 4.78 7.752a3.876 3.876 0 0 1-2.54-.3L.9 7.915c-.046.023-.092.023-.138.023ZM3.186.527C1.616.873.462 2.28.462 3.897c0 .693.184 1.362.577 1.94a.428.428 0 0 1 .046.161l-.23 1.455 1.338-.462c.07-.023.116-.023.185.023.716.37 1.524.462 2.309.3 1.5-.346 2.654-1.708 2.7-3.255a3.426 3.426 0 0 0-1.246-2.793C5.333.573 4.248.319 3.186.526Z"
        className="verdi---team---home__group__chat__noun-chat-1683900__shape"/>
    </g>
  </Svg>
)

export default ChatIcon;