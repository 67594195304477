import * as React from "react"
import {Link} from "gatsby";
import styled from "styled-components";

export const FooterNavStyled = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: calc(100% + 2px);
  background-color: white;
  box-shadow: 0 -7px 13px 0 rgba(0,0,0,0.1);
  border-radius: 14px 14px 0 0;
`;

export const FooterNavContent = styled.div`
  display: flex;
  max-width: 428px;
  height: 76px;
  margin:0 auto;
`;

export const FooterButton = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  padding: 12px 20px;  
  font-family: 'PT Sans', sans-serif;
  text-align: center;
  text-decoration: none;
  color: rgba(0,0,0,0.5);
`;

export const FooterButtonText = styled.span`
  color: ${props => props.color};
`;

const FooterNav = ({children}) => (
  <FooterNavStyled>
    <FooterNavContent>
      {children}
    </FooterNavContent>
  </FooterNavStyled>
)

export default FooterNav;